import { useCallback, useEffect, useRef, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'

const slides = [
  {
    img: '../img/title-1.webp',
    placeholder: '../img/title-1-small.jpg',
    alt: 'title-1',
    title: 'ПРОФЕССИОНАЛЬНАЯ РЕАЛИЗАЦИЯ ПРОЕКТОВ В ОБЛАСТИ ЭНЕРГЕТИКИ',
  },
  {
    img: '../img/title-2.webp',
    placeholder: '../img/title-2-small.jpg',
    alt: 'title-2',
    title:
      'ТЕПЛОВАЯ ИЗОЛЯЦИЯ БЕЗ ПОСРЕДНИКОВ - СОБСТВЕННАЯ ПРОИЗВОДСТВЕННАЯ БАЗА',
  },
  {
    img: '../img/title-3.webp',
    placeholder: '../img/title-3-small.jpg',
    alt: 'title-3',
    title: 'БОЛЕЕ 250 РЕАЛИЗОВАННЫХ КОНТРАКТОВ ПО 223-ФЗ, 44-ФЗ, 275-ФЗ',
  },
  {
    img: '../img/title-4.webp',
    placeholder: '../img/title-4-small.jpg',
    alt: 'title-4',
    title:
      'ИЗГОТОВЛЕНИЕ И МОНТАЖ ПРОМЫШЛЕННЫХ КОТЛОВ ОТЕЧЕСТВЕННОГО ПРОИЗВОДСТВА',
  },
]

function Slider() {
  const [currentIndex, setCurrentIndex] = useState(0)
  const timerRef = useRef(null)
  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1
    setCurrentIndex(newIndex)
  }
  const goToNext = useCallback(() => {
    const isLastSlide = currentIndex === slides.length - 1
    const newIndex = isLastSlide ? 0 : currentIndex + 1
    setCurrentIndex(newIndex)
  }, [currentIndex, slides])

  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }
    timerRef.current = setTimeout(() => {
      goToNext()
    }, 6000)

    return () => clearTimeout(timerRef.current)
  }, [goToNext])
  return (
    <div className="slider__wrapper">
      <div className="slider__left--arrow" onClick={goToPrevious}>
        <button className="slider__button button">❰</button>
      </div>
      <div className="slider__right--arrow" onClick={goToNext}>
        <button className="slider__button button">❱</button>
      </div>
      <AnimatePresence initial={false} mode={'wait'}>
        <motion.div
          key={currentIndex}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            duration: 0.5,
          }}
        >
          <img
            className="slider__picture"
            src={slides[currentIndex].img}
            placeholder={slides[currentIndex].placeholder}
            alt={slides[currentIndex].alt}
          />
          <div className="slider__container">
            <h1 className="slider__title">{slides[currentIndex].title}</h1>
          </div>
        </motion.div>
      </AnimatePresence>
    </div>
  )
}

export default Slider
