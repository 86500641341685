import { Link } from 'react-router-dom'


function Logo() {

  return (
    <Link className="header__logo-link" to="/">
      <img
        className="header__logo"
        src="/img/logo.png"
        alt="ЭСМСЕВЕР"
        width="235"
        height="82"
      />
    </Link>
  )
}

export default Logo
