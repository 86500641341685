import { useCallback, useEffect, useRef, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'

const slideStyles = {
  width: '100%',
  height: '100%',
  borderRadius: '3px',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
}

const slidesContainerStyles = {
  display: 'flex',
  height: '100%',
  transition: 'transform ease-out 0.3s',
}

const slidesContainerOverflowStyles = {
  overflow: 'hidden',
  height: '100%',
}

function Subslider({ pictures, parentWidth, isMobile = 0 }) {
  const timerRef = useRef(null)

  const [currentIndex, setCurrentIndex] = useState(0)

  const [touchPosition, setTouchPosition] = useState(null)

  const handleTouchReaction = (touchDown) => {
    if (touchDown - touchPosition > 0) {
      goToBack()
    }

    if (touchDown - touchPosition < 0) {
      goToNext()
    }
  }
  const handleTouchStart = (e) => {
    const touchDown = e.touches[0].clientX
    setTouchPosition(touchDown)
  }

  const handleTouchMove = useDebouncedCallback((e) => {
    const touchDown = e.touches[0].clientX
    handleTouchReaction(touchDown)
  }, 100)

  const goToNext = useCallback(() => {
    const isLastSlide = currentIndex === pictures.length - 1
    const newIndex = isLastSlide ? 0 : currentIndex + 1
    setCurrentIndex(newIndex)
  }, [currentIndex, pictures])

  const goToBack = useCallback(() => {
    const isFirstSlide = currentIndex === 0
    const newIndex = isFirstSlide ? pictures.length - 1 : currentIndex - 1
    setCurrentIndex(newIndex)
  }, [currentIndex, pictures])

  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex)
  }
  const getSlideStylesWithBackground = (slideIndex) => ({
    ...slideStyles,
    backgroundImage: !isMobile
      ? `url(${pictures[slideIndex]})`
      : `url(${pictures[slideIndex].slice(
          0,
          pictures[slideIndex].length - 4,
        )}-mobile.jpg)`,
    width: `${parentWidth}px`,
  })
  const getSlidesContainerStylesWithWidth = () => ({
    ...slidesContainerStyles,
    width: parentWidth * pictures.length,
    transform: `translateX(${-(currentIndex * parentWidth)}px)`,
  })

  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }
    timerRef.current = setTimeout(() => {
      goToNext()
    }, 5000)

    return () => clearTimeout(timerRef.current)
  }, [goToNext])

  return (
    <div className="subslider">
      <div style={slidesContainerOverflowStyles}>
        <div style={getSlidesContainerStylesWithWidth()}>
          {pictures.map((_, slideIndex) => (
            <div
              key={slideIndex}
              style={getSlideStylesWithBackground(slideIndex)}
              onTouchStart={handleTouchStart}
              onTouchMove={handleTouchMove}
            ></div>
          ))}
        </div>
      </div>
      <div className="dotsContainerStyles">
        {pictures.map((_, slideIndex) => (
          <div
            className={
              currentIndex === slideIndex
                ? 'dotStyle dotStyle--active'
                : 'dotStyle'
            }
            key={slideIndex}
            onClick={() => goToSlide(slideIndex)}
          >
            ●
          </div>
        ))}
      </div>
    </div>
  )
}

export default Subslider
