function BurgerMenu () {
    return( 
    <nav className="burger">
        <div className="burger-btn">
            <span></span>
        </div>
    </nav>
    )
}

export default BurgerMenu;