import presentation from '../../images/presentation.pdf'
import MyImage from '../my-image/my-image'

function WelcomeParagraph({ isMobile }) {
  return (
    <div className="container">
      <div className="welcome__container">
        <div className="welcome__wrapper">
          <h1 className="welcome__title">
            МЫ РАДЫ ВАС ПРИВЕТСТВОВАТЬ НА САЙТЕ НАШЕЙ КОМПАНИИ!
          </h1>
          <p className="welcome__text">
            ООО «ЭСМ Север» основано в 2016 году. Сегодня ООО «ЭСМ Север» -
            коллектив профессионалов, успешно реализующий проекты в области
            энергетики по проектированию, строительству, капитальному ремонту,
            наладочным испытаниям. Основная задача компании – повышение качества
            услуг, за счет применения современных технологий производства. Среди
            наших Заказчиков: предприятия оборонной промышленности, нефтегазовой
            отрасли, социальные объекты, предприятия ТЭК.
            <br />
            Деятельность компании направлена на установление перспективного,
            взаимовыгодного сотрудничества в области промышленной энергетики.
          </p>
          <div className="welcome__signature">
            <div className="welcome__signature--img"></div>
            <div className="welcome__signature--text">
              С уважением, генеральный директор
              <br />
              Дьяков Михаил Владимирович
            </div>
          </div>
        </div>
      </div>
      <div className="button__wrapper">
        <a href={presentation} download="presentation">
          <button className="button welcome__button">
            Скачать презентацию компании {'>>'}
          </button>
        </a>
      </div>
    </div>
  )
}

export default WelcomeParagraph
