import { Link } from 'react-router-dom'

function Footer({ isMobile }) {
  return (
    <>
      {!isMobile ? (
        <footer className="footer">
          <div className="container">
            <div className="footer__wrapper">
              <ul className="footer__contacts">
                <li className="footer__contacts__item">
                  <Link to="/">
                    <img
                      src="/img/logo.png"
                      alt="logo"
                      className="footer__logo"
                    />
                  </Link>
                </li>
                <li className="footer__contacts__item">
                  <div>
                    <img
                      src="/img/location.svg"
                      alt="location"
                      className="icons"
                    />
                    Санкт-Петербург, ул. Смолячкова, д.19, литер 'А',
                  </div>
                  <div className="adress">офис 206, БЦ "ГАП"</div>
                </li>
                <li className="footer__contacts__item">
                  <div>
                    <img src="/img/phone.svg" alt="phone" className="icons" />8
                    (812) 458-88-93
                  </div>
                  <div className="phone">
                    <img src="/img/phone.svg" alt="phone" className="icons" />8
                    (921) 419-71-55
                  </div>
                </li>
                <li className="footer__contacts__item">
                  <img src="/img/email.svg" alt="email" className="icons" />
                  info@esmsever.ru
                </li>
              </ul>
              <div className="copyright">
                <p>© ООО "ЭСМ Север", 2016-2023</p>
              </div>
            </div>
          </div>
        </footer>
      ) : (
        <footer className="footer-mobile">
          <div className="container">
            <div className="footer__wrapper-mobile">
              <ul className="footer__contacts-mobile">
                <li className="footer__contacts__item-mobile">
                  <div>
                    <img
                      src="/img/location.svg"
                      alt="location"
                      className="icons"
                    />
                    СПб, ул. Смолячкова, д.19, литер 'А', офис 206, БЦ "ГАП"
                  </div>
                </li>
                <li className="footer__contacts__item-mobile">
                  <div>
                    <img src="/img/phone.svg" alt="phone" className="icons" />8
                    (812) 458-88-93
                  </div>
                  <div className="phone">
                    <img src="/img/phone.svg" alt="phone" className="icons" />8
                    (921) 419-71-55
                  </div>
                </li>
                <li className="footer__contacts__item-mobile">
                  <img src="/img/email.svg" alt="email" className="icons" />
                  info@esmsever.ru
                </li>
                <li className="footer__contacts__item-mobile">
                  © ООО "ЭСМ Север",
                  <br/> 2016-2023
                </li>
              </ul>
            </div>
          </div>
        </footer>
      )}
    </>
  )
}

export default Footer
