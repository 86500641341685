import Logo from '../logo/logo'
import HeaderNav from '../header-nav/header-nav'
import BurgerMenu from '../burger-menu/burger-menu'
import { useState } from 'react'
import BurgerList from '../burger-list/burger-list'

function Header({isMobile}) {

  const [activeList, setActivList] = useState(0)


  return (
    <header className="header">
      <div className="container">
        <div className="header__wrapper">
          <Logo />
          {!isMobile ? (
            <HeaderNav />
          ) : (
            <div onClick={() => setActivList(!activeList)}>
            <BurgerMenu  />
            </div>
          )}
        </div>
        {activeList ? <BurgerList /> : ''}
      </div>
    </header>
  )
}

export default Header
