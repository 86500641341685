import { useEffect, useRef, useState } from 'react'
import BreadCrumbs from '../../components/breadcrumbs/breadcrumbs'
import Footer from '../../components/footer/footer'
import Header from '../../components/header/header'
import Subslider from '../../components/subslider/subslider'
import { breadcrumbs, services } from '../../const'


const concreteImg = [
  '../img/services/concrete-1.jpg',
  '../img/services/concrete-2.jpg',
  '../img/services/concrete-3.jpg',
]
function Concrete({isMobile} ) {
  window.scrollTo(0,0)
  const divBlock = useRef(null);
  const [widthImage, setWidthImage] = useState(550)

  useEffect(()=>setWidthImage(divBlock.current.offsetWidth),[])
  return (
    <>
      <Header isMobile={isMobile} />
      <BreadCrumbs item={breadcrumbs.services} />
      <div className="container">
        <h1 className="services__title-1">{services[7].title}</h1>
        <div className="subservices__wrapper">
          <div className="subservices__img" ref={divBlock}>
            <Subslider pictures={concreteImg} parentWidth={widthImage} />
          </div>
          <div>
            <p className="subservices__text">
              Выполнение всех видов бетонных работ под фундаменты металлических
              конструкций, тепловых пунктов, котельных, складских сооружений.
              Устройство ленточных фундаментов, монолитных плит и свайных
              оснований в сложных грунтах, с применением буровой техники.
            </p>
            <p className="subservices__text">
              Укладка бетона при минусовых температурах с соблюдением технологии
              полимеризации состава смеси. Наличие аттестованных специалистов
              контроля качества обеспечивает необходимый контроль полного цикла
              бетонирования: от входного контроля, до нормативного набора
              прочности.
            </p>
          </div>
        </div>
      </div>

      <Footer isMobile={isMobile} />
    </>
  )
}

export default Concrete
