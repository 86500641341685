import { useEffect, useRef, useState } from 'react'
import BreadCrumbs from '../../components/breadcrumbs/breadcrumbs'
import Footer from '../../components/footer/footer'
import Header from '../../components/header/header'
import Subslider from '../../components/subslider/subslider'
import { breadcrumbs } from '../../const'

const works = [
  {
    customer: 'КП «МЭД»',
    object: 'г. Москва, ЦТП – 10 шт., 2022 г.',
    description:
      'замена 130 теплообменников, изоляционные работы в ЦТП, замена арматуры, в период отопительного сезона',
    img: ['../img/work/11.jpg', '../img/work/12.jpg', '../img/work/13.jpg'],
  },
  {
    customer: ' ГУП «ТЭК СПб»',
    object: 'г. Санкт-Петербург, котлы, 2022 г.',
    description:
      'капитальный ремонт котла ДКВр 10/13 и замена котла ДКВр 4/13. Восстановление барабанов на котле ДКВр10/13 после обваренных труб под 100% вальцовку. Гидравлические испытания, техническое освидетельствование и щелочение успешно пройдены.',
    img: ['../img/work/21.jpg', '../img/work/22.jpg', '../img/work/23.jpg'],
  },
  {
    customer: 'Нефтяной терминал',
    object: ' г. Санкт-Петербург, тепловая изоляция, 2022 г.',
    description:
      'изготовление, монтаж тепловой изоляции трубопроводов, арматуры, теплообменных аппаратов, емкостей. Освоено 16 000 м2. Работа на высоте.',
    img: [
      '../img/work/37.jpg',
      '../img/work/31.jpg',
      '../img/work/33.jpg',
      '../img/work/35.jpg',
    ],
  },
  {
    customer: 'Нефтяной терминал',
    object: 'г. Санкт-Петербург, АКЗ трубопроводов, 2022 г.',
    description:
      'пескоструйная очистка поверхности трубопроводов и нанесение ЛКП. Применение поверенных профессиональных средств измерения для определения степени подготовки поверхности по показателям: степень очистки, шероховатость, запыленность, степень загрязнения солями, толщина мокрого слоя, адгезия, точка росы, толщина сухого слоя, сплошность.',
    img: [
      '../img/work/46.jpg',
      '../img/work/41.jpg',
      '../img/work/42.jpg',
      '../img/work/43.jpg',
      '../img/work/44.jpg',
      '../img/work/45.jpg',
    ],
  },
  {
    customer: ' ООО “Петербургтеплоэнерго”',
    object:
      'г.Санкт-Петербург, техническое перевооружение, капитальный ремонт котельных, 2021-2022 г.г.',
    description:
      'замена теплообменников, емкостей, трубопроводов ГВС, ХВС, дренажной системы, замена насосов циркуляции',
    img: [
      '../img/work/51.jpg',
      '../img/work/53.jpg',
      '../img/work/55.jpg',
      '../img/work/57.jpg',
    ],
  },
  {
    customer: 'ООО “Петербургтеплоэнерго”',
    object:
      'г.Санкт-Петербург, техническое перевооружение, капитальный ремонт котельных, 2021-2022 г.г.',
    description:
      'проведение обмуровочных работ двух котлов  КВ-ГМ-50-150М, тепловая изоляция трубопровода.',
    img: [
      '../img/work/64.jpg',
      '../img/work/65.jpg',
      '../img/work/66.jpg',
      '../img/work/62.jpg',
      '../img/work/63.jpg',
    ],
  },
  {
    customer: 'ООО “Петербургтеплоэнерго”',
    object: 'г.Санкт-Петербург, проектирование и экспертиза, 2021-2022 г.г.',
    description:
      'разработка рабочей документации в рамках технического перевооружения на замену горелок, систем химводоподготовки. Получение положительного заключения экспертизы пром.безопасности. Замена частотного оборудования.',
    img: [
      '../img/work/71.jpg',
      '../img/work/72.jpg',
      '../img/work/73.jpg',
      '../img/work/74.jpg',
    ],
  },
  {
    customer: ' ООО “ТЕПЛОЭНЕРГО”',
    object: 'г.Санкт-Петербург, тепловая изоляция в котельных, 2021 г.г.',
    description: 'изготовление, монтаж тепловой изоляции в котельных.',
    img: [
      '../img/work/82.jpg',
      '../img/work/81.jpg',
      '../img/work/83.jpg',
      '../img/work/84.jpg',
    ],
  },
  {
    customer: 'Заказчик: ФГАОУ ВО «СПбПУ»',
    object:
      ' г.Санкт-Петербург, капитальный ремонт в котельной и ИТП 2020-2022 г.г.',
    description:
      'работы по замене подогревателей, ремонт котла ДКВр 10/13, замена теплового пункта, пуско-наладочные работы',
    img: [
      '../img/work/92.jpg',
      '../img/work/91.jpg',
      '../img/work/93.jpg',
      '../img/work/94.jpg',
    ],
  },
  {
    customer: ' ООО «РПК-Высоцк «ЛУКОЙЛ-II»',
    object: 'г.Высоцк, проектирование, экспертиза, СМР. 2020 г.г.',
    description:
      'техническое перевооружение коллекторов подачи нефтепродуктов на причалы (проектирование, экспертиза, монтаж, электрообогрев, изоляция).',
    img: [
      '../img/work/103.jpg',
      '../img/work/105.jpg',
      '../img/work/106.jpg',
      '../img/work/107.jpg',
    ],
  },
  {
    customer: 'ООО «РПК-Высоцк «ЛУКОЙЛ-II»',
    object:
      'г.Высоцк, проектирование, ремонт промышленного оборудования, инженерных сетей. 2016-2021 г.г.',
    description:
      'проектирование и модернизация конденсатной линии, изоляционные работы, ремонт артскважин, замена сетей отопления, водоснабжения, ремонт котельного оборудования, обмуровка. Пуско-наладочные и режимно-наладочные испытания котлов.',
    img: [
      '../img/work/116.jpg',
      '../img/work/119.jpg',
      '../img/work/111.jpg',
      '../img/work/113.jpg',
      '../img/work/114.jpg',
      '../img/work/117.jpg',
      '../img/work/1110.jpg',
    ],
  },
  {
    customer: 'ООО «РПК-Высоцк «ЛУКОЙЛ-II»',
    object: 'г.Высоцк, модернизация изоляции. 2017 г.г.',
    description:
      'нанесение тепло - , гидро – изоляции (полимочевины) методом напыления на резервуары нефтепродуктов. Работы на высоте 20 м.',
    img: ['../img/work/121.jpg', '../img/work/122.jpg'],
  },
  {
    customer: 'ФАУ «25 ГосНИИ химмотологии Минобороны России»',
    object:
      'г. Москва, ФАУ «25 ГосНИИ химмотологии Минобороны России», модернизация теплового пункта», 2019 г.',
    description:
      'проектирование, замена в 100% объеме теплового пункта, инъецирование стен, абразивная очистка конструкций кровли, устройство пола, установка оборудования, пуско-наладка, обучение персонала Заказчика.',
    img: [
      '../img/work/1312.jpg',
      '../img/work/137.jpg',
      '../img/work/135.jpg',
      '../img/work/136.jpg',
      '../img/work/138.jpg',
      '../img/work/139.jpg',
      '../img/work/1310.jpg',
    ],
  },
  {
    customer: 'ООО «Петербургтеплоэнерго» ',
    object: 'г.Санкт-Петербург, тепловая сеть, 2021 г.',
    description:
      ' реконструкция тепловой сети отопления от котельной до жилых домов в Петроградском районе СПб. Проектирование, согласования, СМР, благоустройство, сдача работ.',
    img: [
      '../img/work/143.jpg',
      '../img/work/141.jpg',
      '../img/work/142.jpg',
      '../img/work/144.jpg',
    ],
  },
  {
    customer: 'ООО «ТЕПЛОЭНЕРГО» ',
    object:
      'г. Санкт-Петербург, тепловая сеть, резервуары, АКЗ, изоляция, 2020-2021 г. г.',
    description:
      'капитальный ремонт тепловой сети на ТЭЦ (Dn900 мм), тепловая изоляция деаэратора, диагностика, ремонт фундамента и поясов баков аккумуляторов ГВС, пескоструйная обработка поверхности, нанесение ЦВЭС.',
    img: ['../img/work/151.jpg', '../img/work/154.jpg', '../img/work/156.jpg'],
  },
  {
    customer: 'АО «ГОЗНАК»',
    object:
      'г. Санкт-Петербург, демонтаж, ремонт котлов, инсенератора, пожарный трубопроводов, тепловая изоляция, 2018 -2021 г.г.',
    description:
      ' работы по демонтажу котла ДЕ25, инсинератора ИН-50.2, капитальный ремонт, обмуровка котлов ДЕ 25,  замена пожарных трубопроводов, тепловая изоляция оборудования, режимно-наладочные испытания котлов, системы ХВО.',
    img: [
      '../img/work/161.jpg',
      '../img/work/163.jpg',
      '../img/work/164.jpg',
      '../img/work/165.jpg',
    ],
  },
  {
    customer: 'ПАО «МОЭК»',
    object:
      'г.Москва, замена подогревателей, обмуровка, ремонт оборудования 2017-2020 г.г.',
    description:
      'выполнена замена более 500 подогревателей кожухотрубных, проведена обмуровка котлов, выполнен ремонт оборудования котельных (трубопроводы, фильтры ФИПа).',
    img: [
      '../img/work/172.jpg',
      '../img/work/174.jpg',
      '../img/work/176.jpg',
      '../img/work/177.jpg',
      '../img/work/178.jpg',
      '../img/work/179.jpg',
    ],
  },
  {
    customer: 'ООО «Фертоинг»',
    object:
      'Ленинградская область, Бухта Дальняя, замена створных знаков. 2021 г.',
    description:
      'комплекс работ по разработке КМД, замене фундаментов, изготовлению, монтажу навигационных знаков, в том числе выполнение антикоррозионной защиты металлоконструкций.',
    img: [
      '../img/work/182.jpg',
      '../img/work/183.jpg',
      '../img/work/186.jpg',
      '../img/work/187.jpg',
      '../img/work/189.jpg',
      '../img/work/1810.jpg',
      '../img/work/1813.jpg',
      '../img/work/1814.jpg',
      '../img/work/1816.jpg',
    ],
  },
  {
    customer:
      'дочерние организации ПАО «Транснефть» - ООО «Транснефть-Балтика», АО «Транснефть-Дружба», АО «Транснефть- Верхняя Волга», АО «Транснефть-Север»',
    object:
      'котельные и тепловые пункты – регионы РФ, ремонт, сервисное обслуживание, 2017-2022 г.г.',
    description:
      'режимно-наладочные испытания, техническое обслуживание, тепловых пунктов, котельных, тепловых сетей. Ремонт котельного оборудования, замена насосов, дымовых труб, АКЗ. Замена внутренних сетей отопления.',
    img: [
      '../img/work/192.jpg',
      '../img/work/191.jpg',
      '../img/work/194.jpg',
      '../img/work/196.jpg',
      '../img/work/198.jpg',
      '../img/work/199.jpg',
    ],
  },
  {
    customer: 'АО «ИЭК»',
    object: 'г.Санкт-Петербург, проектирование, замена газохода, 2020 г.',
    description:
      'комплекс работ по проектированию, монтажу, изготовлению газохода. Устройство фундамента, монтаж изоляции. Демонтаж дымовой трубы высотой 50м.',
    img: [
      '../img/work/203.jpg',
      '../img/work/205.jpg',
      '../img/work/206.jpg',
      '../img/work/209.jpg',
      '../img/work/2010.jpg',
      '../img/work/2011.jpg',
      '../img/work/2013.jpg',
    ],
  },
  {
    customer: 'ООО «ДВК-ДОРСТРОЙ»',
    object: ' г. Санкт-Петербург, внутренние системы отопления 2019-2020 г.г.',
    description:
      'проектирование, монтаж, ПНР, внутренних систем отопления здания школы и ФГБОУ ДВО ФНС РФ.',
    img: [
      '../img/work/217.jpg',
      '../img/work/211.jpg',
      '../img/work/218.jpg',
      '../img/work/213.jpg',
      '../img/work/215.jpg',
      '../img/work/216.jpg',
      '../img/work/2110.jpg',
    ],
  },
  {
    customer: 'АО «ММЗ «АВАНГАРД»',
    object: 'г.Москва, обмуровка двух котлов ДКВ 10/13, 2020г. ',
    description: 'выполнение работ по обмуровке двух паровых котлов ДКВ 10/13.',
    img: [
      '../img/work/221.jpg',
      '../img/work/223.jpg',
      '../img/work/224.jpg',
      '../img/work/227.jpg',
      '../img/work/228.jpg',
      '../img/work/229.jpg',
    ],
  },
  {
    customer: 'АО «Птицефабрика «Северная»',
    object:
      'Ленинградская область, техническое перевооружение паропровода. 2019 г.',
    description:
      ' выполнение комплекса работ по изысканиям, проектированию, экспертизе, строительству паропровода от котельной до цеха гранулирования.',
    img: [
      '../img/work/235.jpg',
      '../img/work/234.jpg',
      '../img/work/237.jpg',
      '../img/work/238.jpg',
      '../img/work/239.jpg',
    ],
  },
  {
    customer: 'ОАО «РЖД»',
    object: ' Ремонт котлов, работа в регионах. ТО и РНИ 2017-2020 г.г.',
    description:
      ' выполнение работ по капитальному ремонту котлов ДКВр, ДЕ, КВГМ, проведение режимно-наладочных испытаний, техническое обслуживание 14 котельных.',
    img: [
      '../img/work/248.jpg',
      '../img/work/241.jpg',
      '../img/work/242.jpg',
      '../img/work/244.jpg',
      '../img/work/245.jpg',
      '../img/work/249.jpg',
    ],
  },
  {
    customer: 'ОАО «Красный Октябрь»',
    object: 'г. Санкт-Петербург, ремонт котлов. 2019-2020 г.г.',
    description: ' выполнение капитального ремонта паровых котлов ДКВР20-13.',
    img: ['../img/work/252.jpg', '../img/work/253.jpg', '../img/work/254.jpg'],
  },
]

function Work({isMobile}) {
  const divBlock = useRef(null);
  const [widthImage, setWidthImage] = useState(550)

  useEffect(()=>setWidthImage(divBlock.current.offsetWidth),[])
  
  const [currenCount, setCurrentCount] = useState(6)
  const [dinamicWorks, setDinamicWorks] = useState(works.slice(0, currenCount))

  const scrollHandle = (e) => {

    if (
      e.target.documentElement.scrollHeight -
        (e.target.documentElement.scrollTop + window.innerHeight) <
      200 && dinamicWorks.length <= works.length
    ) {
      setDinamicWorks([
        ...dinamicWorks,
        ...works.slice(currenCount, currenCount+3),
      ])
      setCurrentCount(currenCount + 3)
    }
  }
  useEffect(() => { 
    if (currenCount ===6) {
    window.scrollTo(0,0)
  }})
  useEffect(() => {
    document.addEventListener('scroll', scrollHandle)
    return function () {
      document.removeEventListener('scroll', scrollHandle)
    }
  })

  return (
    <>
      <Header isMobile={isMobile}/>
      <BreadCrumbs item={breadcrumbs.works} />
      <div className="container">
        <h1 className="services__title-1">
          БОЛЕЕ 250 РЕАЛИЗОВАННЫХ КОНТРАКТОВ, СРЕДИ КОТОРЫХ:
        </h1>
        {dinamicWorks.map((item) => (
          <div className="subservices__wrapper" key={item.description}>
            <div className="subservices__img" ref={divBlock}>
              {item.img.length > 1 ? (
                <Subslider isMobile={isMobile} pictures={item.img} parentWidth={widthImage} />
              ) : (
                ''
              )}
            </div>
            <div className="subservices__text">
              <p>
                <strong className="strong">Заказчик: </strong>
                {item.customer}
              </p>
              <p>
                <strong className="strong">Объект: </strong>
                {item.object}
              </p>
              <p>
                <strong className="strong">Описание работ: </strong>
                {item.description}
              </p>
            </div>
          </div>
        ))}
      </div>
      <Footer isMobile={isMobile}/>
    </>
  )
}

export default Work
