import { useEffect, useRef, useState } from 'react'
import BreadCrumbs from '../../components/breadcrumbs/breadcrumbs'
import Footer from '../../components/footer/footer'
import Header from '../../components/header/header'
import Subslider from '../../components/subslider/subslider'
import { breadcrumbs, services } from '../../const'


const images =[
  '../img/services/installation-1.jpg',
  '../img/services/installation-2.jpg',
  '../img/services/installation-3.jpg',
  '../img/services/installation-4.jpg',
  '../img/services/installation-5.jpg',
]
function Installation({isMobile} ) {
  window.scrollTo(0,0)
  const divBlock = useRef(null);
  const [widthImage, setWidthImage] = useState(550)

  useEffect(()=>setWidthImage(divBlock.current.offsetWidth),[])
  return (
    <>
      <Header isMobile={isMobile} />
      <BreadCrumbs item={breadcrumbs.services} />
      <div className="container">
        <h1 className="services__title-1">{services[4].title}</h1>
        <div className="subservices__wrapper">
          <div className="subservices__img" ref={divBlock}>
            <Subslider pictures={images} parentWidth={widthImage} />
          </div>
          <div>
            <h2 className="subservices__title-2">
              ПРОИЗВОДСТВО МЕТАЛЛОКОНСТРУКЦИЙ И БЛОК-МОДУЛЕЙ
            </h2>
            <p className="subservices__text">
              Изготовление, контрольная сборка, нанесение антикоррозионной
              защиты на металлоконструкции, на производственной базе ООО «ЭСМ Север»
              : мачты, дымовые трубы, створные навигационные
              знаки, фермы, решетчатые конструкции вентиляционных шахт и т.п.
              Сборка блок-модулей котельных, насосных станций с доставкой и
              установкой на объект Заказчика.
            </p>
          </div>
        </div>
      </div>

      <Footer isMobile={isMobile} />
    </>
  )
}

export default Installation
