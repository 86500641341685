export const breadcrumbs = {
  services: 'services',
  works: 'works',
  reviews: 'reviews',
  licenses: 'licenses',
  contacts: 'contacts',
}

export const breadcrumbsTranslate = {
  services: 'Услуги',
  works: 'Выполненные работы',
  reviews: 'Отзывы',
  licenses: 'Лицензии',
  contacts: 'Контакты',
}

export const appRoute = {
  main: '/',
  services: '/services',
  works: '/works',
  reviews: '/reviews',
  licenses: '/licenses',
  contacts: '/contacts',
}

export const licensesList = {
  1: [
    'licenses-11.jpg',
    'licenses-12.jpg',
    'licenses-13.jpg',
    'licenses-14.jpg',
  ],
  2: ['licenses-21.jpg', 'licenses-22.jpg'],
  3: ['licenses-31.jpg', 'licenses-32.jpg', 'licenses-33.jpg'],
  4: ['licenses-41.jpg', 'licenses-42.jpg'],
  5: ['licenses-51.jpg','licenses-52.jpg'],
  6: ['licenses-61.jpg'],
  7: ['licenses-71.jpg'],
}

const N = 34
export const arrayImgReviews = Array.from(Array(N + 1).keys())
  .slice(1)
  .map((item) => 'img/reviews/reviews' + item + '.jpg')

export const services = [
  {
    list: 'СТРОИТЕЛЬСТВО, КАПИТАЛЬНЫЙ РЕМОНТ',
    title:
      'Строительство, капитальный ремонт котельных, тепловых пунктов и инженерных сетей',
    img: 'img/services/construction.webp',
    placeholder: 'img/services/construction.jpg',
    text:
      'Строительство блок-модульных котельных, центральных и индивидуальных тепловых пунктов. Строительство инженерных сетей. Проектирование, согласование, монтаж.',
    alt: '/construction',
  },
  {
    list: 'ИЗОЛЯЦИЯ',
    title: 'Тепло, гидроизоляция трубопроводов и оборудования',
    img: 'img/services/insulation.webp',
    placeholder: 'img/services/insulation.jpg',
    text:
      'Работа со всеми видами тепловой изоляции. Изоляция оборудования и трубопроводов. Нанесение полимочевины на резервуары и емкости.',
    alt: '/insulation',
  },
  {
    list: 'ПРОЕКТИРОВАНИЕ',
    title: 'Проектирование и согласование',
    img: 'img/services/design.webp',
    placeholder: 'img/services/design.jpg',
    text:
      'Выполняем полный комплекс по проектированию строительства и реконструкции инженерных систем в сфере теплоэнергетики.',
    alt: '/design',
  },
  {
    list: 'АНТИКОРРОЗИЦИОННАЯ ЗАЩИТА',
    title: 'Антикоррозионная защита металлоконструкций',
    img: 'img/services/anticorrosition.webp',
    placeholder: 'img/services/anticorrosition.jpg',
    text:
      'Антикоррозионная защита металлоконструкций. Работа на высоте и в стесненных условиях (баки, резервуары, дымовые трубы, мачты).',
    alt: '/anticorrosition',
  },
  {
    list: 'ПРОИЗВОДСТВО МЕТАЛЛОКОНСТРУКЦИЙ',
    title: 'Производство и монтаж металлоконструкций',
    img: 'img/services/installation.webp',
    placeholder: 'img/services/installation.jpg',
    text:
      'Изготовление, контрольная сборка, нанесение антикоррозионной защиты на металлоконструкции.',
    alt: '/installation',
  },
  {
    list: 'НАЛАДОЧНЫЕ ИСПЫТАНИЯ',
    title: 'Наладочные испытания и техническое обслуживание',
    img: 'img/services/adjustment.webp',
    placeholder: 'img/services/adjustment.jpg',
    text:
      'Проведение наладочных испытаний котлов, систем ХВП, водно-химического режима оборудования. Выполнение пуско-наладочных испытаний газопоршневых установок энергоблоков.',
    alt: '/adjustment',
  },
  {
    list: 'ВНУТРЕННИЕ ИНЖЕНЕРНЫЕ СИСТЕМЫ',
    title: 'Внутренние инженерные системы',
    img: 'img/services/engineering.webp',
    placeholder: 'img/services/engineering.jpg',
    text:
      'Выполнение работ по проектированию, монтажу, пуско-наладке внутренних инженерных систем зданий гражданского и производственного назначения.',
    alt: '/engineering',
  },
  {
    list: 'БЕТОНИРОВАНИЕ ОСНОВАНИЙ',
    title: 'Бетонирование оснований',
    img: 'img/services/concrete.webp',
    placeholder: 'img/services/concrete-small.jpg',
    text:
      'Выполнение всех видов бетонных работ под фундаменты металлических конструкций, тепловых пунктов, котельных, складских сооружений.',
    alt: '/concrete',
  },
]
