import { useEffect, useState } from 'react'
import BreadCrumbs from '../../components/breadcrumbs/breadcrumbs'
import Footer from '../../components/footer/footer'
import Header from '../../components/header/header'
import ReviewsPopup from '../../components/reviews-popup/reviews-popup'
import { breadcrumbs, arrayImgReviews } from '../../const'
import MyImage from '../../components/my-image/my-image'

function Reviews({ isMobile }) {
  const [activePopup, setActivePopup] = useState(0)
  const [currenCount, setCurrentCount] = useState(6)
  const [image, setImage] = useState(arrayImgReviews.slice(0, currenCount))

  const scrollHandle = (e) => {
    if (
      e.target.documentElement.scrollHeight -
        (e.target.documentElement.scrollTop + window.innerHeight) <
      200 && image.length <= arrayImgReviews.length
    ) {
      setImage([
        ...image,
        ...arrayImgReviews.slice(currenCount, currenCount+3),
      ])
      setCurrentCount(currenCount + 3)
    }
  }
  useEffect(() => { 
    if (activePopup === 0 && currenCount ===6) {
    window.scrollTo(0,0)
  }})
  useEffect(() => {
    document.addEventListener('scroll', scrollHandle)
    return function () {
      document.removeEventListener('scroll', scrollHandle)
    }
  })
  return (
    <>
      <Header isMobile={isMobile} />
      <BreadCrumbs item={breadcrumbs.reviews} />
      <div className="container">
        <h1 className="pictures__title">ОТЗЫВЫ</h1>
        <div className="pictures__wrapper">
          {image.map((item) => (
            <div
              className="pictures"
              key={item}
              onClick={() => setActivePopup(item)}
            >
              <MyImage
                cl={'picture'}
                src={!isMobile ? item : item.slice(0,item.length-3) + 'webp'}
                placeholder={item.slice(0, item.length - 4) + '-small.jpg'}
                alt="reviews"
              />
            </div>
          ))}
        </div>
      </div>
      <Footer isMobile={isMobile} />
      {activePopup && !isMobile ? (
        <ReviewsPopup
          activePopup={activePopup}
          setActivePopup={setActivePopup}
        />
      ) : (
        ''
      )}
    </>
  )
}

export default Reviews
