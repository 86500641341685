import { Route, Routes } from 'react-router'
import Main from '../../pages/main/main'
import Services from '../../pages/services/services'
import Work from '../../pages/work/work'
import Reviews from '../../pages/reviews/reviews'
import Licenses from '../../pages/licenses/licenses'
import Contacts from '../../pages/contacts/contacts'
import Construction from '../../pages/construction/construction'
import Insulation from '../../pages/insulation/insulation'
import Design from '../../pages/design/design'
import Anticorrosition from '../../pages/anticorrosition/anticorrosition'
import Installation from '../../pages/installation/installation'
import Adjustment from '../../pages/adjustment/adjustment'
import Engineering from '../../pages/engineering/engineering'
import Concrete from '../../pages/concrete/concrete'
import { useEffect, useState } from 'react'
import NotFoundScreen from '../../pages/no-found-screen/no-found-screen'

function App() {
  const [isMobile, setIsMobile] = useState(0)

  useEffect(() => {
    if (window.innerWidth < 926) {
      setIsMobile(1)
    }
  }, [])
  return (
    <Routes>
      <Route path="/" element={<Main isMobile={isMobile} />} />
      <Route path="/services" element={<Services isMobile={isMobile} />} />
      <Route
        path="/construction"
        element={<Construction isMobile={isMobile} />}
      />
      <Route path="/design" element={<Design isMobile={isMobile} />} />
      <Route
        path="/anticorrosition"
        element={<Anticorrosition isMobile={isMobile} />}
      />
      <Route
        path="/installation"
        element={<Installation isMobile={isMobile} />}
      />
      <Route path="/adjustment" element={<Adjustment isMobile={isMobile} />} />
      <Route
        path="/engineering"
        element={<Engineering isMobile={isMobile} />}
      />
      <Route path="/concrete" element={<Concrete isMobile={isMobile} />} />
      <Route path="/insulation" element={<Insulation isMobile={isMobile} />} />
      <Route path="/work" element={<Work isMobile={isMobile} />} />
      <Route path="/reviews" element={<Reviews isMobile={isMobile} />} />
      <Route path="/licenses" element={<Licenses isMobile={isMobile} />} />
      <Route path="/contacts" element={<Contacts isMobile={isMobile} />} />
      <Route path="*" element={<NotFoundScreen isMobile={isMobile} />} />
    </Routes>
  )
}

export default App
