import { useEffect, useRef, useState } from 'react'
import BreadCrumbs from '../../components/breadcrumbs/breadcrumbs'
import Footer from '../../components/footer/footer'
import Header from '../../components/header/header'
import Subslider from '../../components/subslider/subslider'
import { breadcrumbs, services } from '../../const'


const images = [
  '../img/services/adjustment-1.jpg',
  '../img/services/adjustment-2.jpg',
  '../img/services/adjustment-3.jpg',
  '../img/services/adjustment-4.jpg',
  '../img/services/adjustment-5.jpg',
  '../img/services/adjustment-6.jpg',
]
function Adjustment({isMobile} ) {
  window.scrollTo(0,0)
  const divBlock = useRef(null);
  const [widthImage, setWidthImage] = useState(550)

  useEffect(()=>setWidthImage(divBlock.current.offsetWidth),[])
  return (
    <>
      <Header isMobile={isMobile} />
      <BreadCrumbs item={breadcrumbs.services} />
      <div className="container">
        <h1 className="services__title-1">{services[5].title}</h1>
        <div className="subservices__wrapper">
          <div className="subservices__img" ref={divBlock}>
            <Subslider pictures={images} parentWidth={widthImage} />
          </div>
          <div>
            <h2 className="subservices__title-2">
              НАЛАДКА И ТЕХНИЧЕСКОЕ ОБСЛУЖИВАНИЕ
            </h2>
            <p className="subservices__text">
              Проведение наладочных испытаний котлов, систем ХВП,
              водно-химического режима оборудования. Выполнение пуско-наладочных
              испытаний газопоршневых установок энергоблоков. Работа с
              импортными и отечественными контроллерами. Выполнение РНИ тепловых
              сетей, расчет и шайбирование. Наладочные испытания проводятся на
              основании методик, согласованных в Ростехнадзоре. Техническое
              обслуживание оборудования котельных и тепловых пунктов. Аварийное
              круглосуточное сопровождение объектов. Применение современного
              оборудования контроля: газоанализаторы, дифманометры, тепловизоры,
              ультразвуковые расходомеры среды, пирометры и т.д.
            </p>
          </div>
        </div>
      </div>

      <Footer isMobile={isMobile} />
    </>
  )
}

export default Adjustment
