import { YMaps, Map, Placemark , ZoomControl} from '@pbe/react-yandex-maps'
import { useRef } from 'react';

function MapContacts({isMobile} ) {
  const mapRef = useRef();
  return (
    <div className="container">
      <YMaps>
        <Map
          defaultState={{
            center: [59.944454, 30.323306],
            zoom: 12,
          }}
          width="100%"
          height={ !isMobile? "700px" : "350px"}
          instanceRef={mapRef}
        >
          <Placemark
            geometry={[59.9708, 30.3466]}
            properties={{
              iconCaption: 'ул. Смолячкова, д.19, БЦ ГАП',
            }}
          />
          <Placemark
            geometry={[59.924817, 30.273261]}
            properties={{
              iconCaption: 'Перевозная улица, 1Б' }}
          />
          <ZoomControl/>
        </Map>
      </YMaps>
    </div>
  )
}

export default MapContacts
