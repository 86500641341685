import { Link } from 'react-router-dom';
import { appRoute, breadcrumbsTranslate } from '../../const';

function BreadCrumbs({item}) {
  return (
    <div className="container">
      <ul className="breadcrumbs__nav">
        <li className="breadcrumbs__nav-item">
        <Link to="/" className="breadcrumbs__nav-link">Главная</Link>
        </li>
        <li className="breadcrumbs__nav-item">
          <img
            className="breadcrumbs__icon"
            src="/img/rectangle.png"
            alt="rectangle"
          />
        </li>
        <li className="breadcrumbs__nav-item" >
        <Link to={appRoute[item]}className="breadcrumbs__nav-link">{breadcrumbsTranslate[item]}</Link>
        </li>
      </ul>
    </div>
  )
}

export default BreadCrumbs
