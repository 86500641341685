
import { Link } from 'react-router-dom';
import Header from '../../components/header/header';

function NotFoundScreen({isMobile}) {
  return (
      <>
      <Header isMobile={isMobile} />
    <div className="container"> 
      <h1 className="not-page__title" >404. Page not found</h1>
      <p>
        <Link className="not-page" to="/">Вернуться на главную</Link>
      </p>
    </div>
    </>
  );
}
export default NotFoundScreen;