import { useState } from 'react'
import { arrayImgReviews } from '../../const'

function ReviewsPopup({ activePopup, setActivePopup }) {
  const [activeCount, setActiveCount] = useState(
    arrayImgReviews.indexOf(activePopup),
  )

  return (
    <div
      className={activePopup ? `licenses-popup active` : `licenses-popup`}
      onClick={() => {
        setActiveCount(0)
        setActivePopup(false)
      }}
    >
      <div
        className="slider__left--arrow popup"
        onClick={(e) => {
          e.stopPropagation()
          activeCount > 0
            ? setActiveCount(activeCount - 1)
            : setActiveCount(arrayImgReviews.length - 1)
        }}
      >
        <button className="button popup">❰</button>
      </div>

      <div
        className="slider__right--arrow popup"
        onClick={(e) => {
          e.stopPropagation()
          activeCount < arrayImgReviews.length - 1
            ? setActiveCount(activeCount + 1)
            : setActiveCount(0)
        }}
      >
        <button className="button popup">❱</button>
      </div>

      <img
        onClick={(e) => e.stopPropagation()}
        className={
          activePopup ? `license-popup__img active` : `license-popup__img`
        }
        src={arrayImgReviews[activeCount]}
        alt="reviews-popup"
      />
    </div>
  )
}

export default ReviewsPopup
