import Footer from '../../components/footer/footer'
import Header from '../../components/header/header'
import Slider from '../../components/slider/slider'
import WelcomeParagraph from '../../components/welcome-paragraph/welcome-paragraph'

function Main({isMobile}) {
  window.scrollTo(0, 0)
  return (
    <>
      <Header isMobile={isMobile} />
      <Slider />
      <WelcomeParagraph isMobile={isMobile} />
      <Footer isMobile={isMobile} />
    </>
  )
}

export default Main
