import { useEffect, useRef, useState } from 'react'
import BreadCrumbs from '../../components/breadcrumbs/breadcrumbs'
import Footer from '../../components/footer/footer'
import Header from '../../components/header/header'
import Subslider from '../../components/subslider/subslider'
import { breadcrumbs, services } from '../../const'

const images = [
  '../img/services/design-11.jpg',
  '../img/services/design-12.jpg'

]
const designComplex = [
  'получение исходно-разрешительной документации;',
  'проведение предпроектных проработок;',
  'разработка проектной документации в соответствии с требованиями Постановления Правительства Российской Федерации от 16 февраля 2008 года № 87;',
  'разработка рабочей документации;',
  'согласование разработанного проекта в необходимых инстанциях;',
  'сопровождение разработанного проекта в государственной экспертизе.',
]

const designList = [
  'водогрейных и паровых котельных мощностью до 100МВт, стационарных, блочно-модульных (заводское изготовление), транспортабельных, отдельностоящих, пристроенных, встроенных, крышных, работающих на газообразных и жидких топливах;',
  'автономных источников тепловой энергии;',
  'котельные и системы отопления загородных домов, коттеджей;',
  'центральных тепловых пунктов (ЦТП);',
  'индивидуальных тепловых пунктов (ИТП);',
  'узлов учета тепловой энергии (УУТЭ);',
  'узлов учета расхода газа (УУРГ);',
  'автономных систем топливоснабжения (хранилища СУГ и жидкого топлива);',
  'наружных и внутренних инженерных сетей;',
  'сетей связи, систем видеонаблюдения, охранно-пожарной сигнализации, контроля доступа, и т.д. для объектов и территорий различного назначения (промышленных и частных - загородных домов, коттеджей);',
  'стальных дымовых труб (самонесущих, в обечайке, в пространственной решетчатой конструкции);',
  'металлических конструкций различного конструктивного назначения;',
  'предельно-допустимых выбросов веществ в атмосферный воздух;',
  'паспортов фасадов зданий.',
]

function Design({isMobile} ) {
  window.scrollTo(0,0)
  const divBlock = useRef(null);
  const [widthImage, setWidthImage] = useState(550)

  useEffect(()=>setWidthImage(divBlock.current.offsetWidth),[])
  return (
    <>
      <Header isMobile={isMobile} />
      <BreadCrumbs item={breadcrumbs.services} />
      <div className="container">
        <h1 className="services__title-1">{services[2].title}</h1>
        <div className="subservices__wrapper">
          <div className="subservices__img" ref={divBlock}>
            <Subslider pictures={images} parentWidth={widthImage} />
          </div>
          <div>
            <h2 className="subservices__title-2">
              ПРОЕКТИРОВАНИЕ ИНЖЕНЕРНЫХ СИСТЕМ
            </h2>
            <ul className="subservices__text">
              ООО «ЭСМ Север» выполняет полный комплекс по проектированию
              строительства и реконструкции инженерных систем и их комплексов в
              сфере теплоэнергетики:
              {designComplex.map((item) => (
                <li key={item}>{item}</li>
              ))}
            </ul>
          </div>
        </div>
        <div className="subservices__wrapper">
          <div className="subservices__img">
            <img src="/img/services/design-3.jpg" alt="design" />
          </div>
          <div>
            <h2 className="subservices__title-2">ПРОЕКТЫ</h2>
            <ul className="subservices__text">
              Специалисты ООО «ЭСМ Север» разрабатывают проекты:
              {designList.map((item) => (
                <li key={item}>{item}</li>
              ))}
            </ul>
          </div>
        </div>
        <div className="subservices__wrapper">
          <div className="subservices__img">
            <img src="/img/services/design-2.jpg" alt="design" />
          </div>
          <div>
            <p className="subservices__text">
              Во всех проектах ООО «ЭСМ Север» применяются прогрессивные
              технические решения с использованием современного
              энергоэффективного оборудования ведущих отечественных и импортных
              производителей, позволяющие обеспечить качественное регулирование
              температурного графика в системах отопления, снизить затраты на
              топливо и электроэнергию, снизить потери тепловой энергии в
              наружных сетях, обеспечить на высоком уровне безопасную работу
              всего объекта в период эксплуатации. Предусматриваемый комплекс
              автоматизации котельных а так же, при необходимости, устройство
              системы дистанционного контроля за технологическими процессами
              позволяют исключить необходимость постоянного присутствия
              обслуживающего персонала на объекте. Графическая часть проекта
              выполняется с использованием технологии 3d моделирования в
              программном комплексе AutoCad, что повышает качество
              разработанного проекта и позволяет избежать несостыковок в крупных
              проектах, насыщенных множеством инженерных коммуникаций.
            </p>
          </div>
        </div>
      </div>

      <Footer isMobile={isMobile}  />
    </>
  )
}

export default Design
