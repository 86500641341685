import { useEffect, useState } from "react"

function MyImage({ src, placeholder, alt, cl }) {
    const [imageSrc, setImageSrc] = useState(placeholder)

    const cn =`${cl}`+`${imageSrc === placeholder ? ' loading' : ' loaded'}` 
    useEffect (()=>{
        const img = new Image();
        img.src = src;
        img.onload = () => {
            setImageSrc(src)
        }
    })
  return (
    <img
      className={cn}
      src={imageSrc}
      alt={alt}
      effect="blur"
    />
  )
}

export default MyImage
