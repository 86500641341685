import BreadCrumbs from '../../components/breadcrumbs/breadcrumbs'
import Footer from '../../components/footer/footer'
import Header from '../../components/header/header'
import { breadcrumbs, services } from '../../const'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import MyImage from '../../components/my-image/my-image'

function Services({ isMobile }) {
  const navigate = useNavigate()
  const [serviceItem, setServiceItem] = useState('')
  const [currenCount, setCurrentCount] = useState(4)
  const [dinamicServices, setDinamicServices] = useState(
    services.slice(0, currenCount),
  )

  const scrollHandle = (e) => {
    console.log(
      e.target.documentElement.scrollHeight -
        (e.target.documentElement.scrollTop + window.innerHeight),
    )
    if (
      e.target.documentElement.scrollHeight -
        (e.target.documentElement.scrollTop + window.innerHeight) <
        200 &&
      dinamicServices.length <= services.length
    ) {
      setDinamicServices([
        ...dinamicServices,
        ...services.slice(currenCount, currenCount + 4),
      ])
      setCurrentCount(currenCount + 4)
    }
  }
  useEffect(() => {
    if (currenCount === 4) {
      window.scrollTo(0, 0)
    }
  })
  useEffect(() => {
    document.addEventListener('scroll', scrollHandle)
    return function () {
      document.removeEventListener('scroll', scrollHandle)
    }
  })
  useEffect(() => {
    serviceItem && navigate(serviceItem)
  }, [serviceItem])

  return (
    <>
      <Header isMobile={isMobile} />
      <BreadCrumbs item={breadcrumbs.services} />
      <div className="container">
        <h1 className="services__title-1">УСЛУГИ</h1>
        <div className="services__wrapper">
          {dinamicServices.map((service) => (
            <div
              className="service"
              key={service.alt}
              onClick={() => setServiceItem(service.alt)}
            >
              <MyImage
                src={service.img}
                alt={service.alt}
                placeholder={service.placeholder}
                cl={'service__img'}
              />
              <h2 className="service__title-2">{service.title}</h2>
              <p className="service__text">{service.text}</p>
              <Link className="service__button" to={service.alt}>
                <button className=" button">Подробнее</button>
              </Link>
            </div>
          ))}
        </div>
      </div>
      <Footer isMobile={isMobile} />
    </>
  )
}

export default Services
