import BreadCrumbs from '../../components/breadcrumbs/breadcrumbs'
import Footer from '../../components/footer/footer'
import Header from '../../components/header/header'
import MapContacts from '../../components/map-contacts/map-contacts'
import { breadcrumbs } from '../../const'
import details from '../../images/details.pdf'

function Contacts({isMobile} ) {
  window.scrollTo(0,0)
  return (
    <>
      <Header isMobile={isMobile} />
      <BreadCrumbs item={breadcrumbs.contacts} />
      <div className="container">
        <ul className="contacts__list">
          <li className="contacts__item">
            <div className="contacts__item-title">Режим работы</div>
            <div>ПН-ПТ: с 9:00 до 18:00</div>
          </li>
          <li className="contacts__item">
            <div className="contacts__item-title">Адрес</div>

            <div>
              <img src="img/location.svg" alt="location" className="icons" />
              <strong className="strong">Офис:</strong> Санкт-Петербург, ул.
              Смолячкова, д.19, литер 'А', офис 206, БЦ "ГАП"{' '}
            </div>

            <div>
              <img src="img/location.svg" alt="location" className="icons" />
              <strong className="strong">Производство:</strong> Санкт-Петербург,
              Перевозная улица, 1Б{' '}
            </div>
          </li>
          <li className="contacts__item">
            <div className="contacts__item-title">Телефон</div>
            <div>
              <a href="tel:+78124588893">8 (812) 458-88-93</a>
            </div>
            <div className="phone">
              <a href="tel:+79214197155">8 (921) 419-71-55</a>
            </div>
          </li>
          <li className="contacts__item">
            <div className="contacts__item-title">Почта</div>
            <div>
              <div>info@esmsever.ru</div>
            </div>
          </li>
        </ul>
        <div className="contacts__item-title details">
          Реквизиты
          <div>
            <a href={details} download="details">
              <button className="button__details ">
                СКАЧАТЬ КАРТОЧКУ ПРЕДПРИЯТИЯ {'>>'}
              </button>
            </a>
          </div>
        </div>
      </div>
      <div className="map__wrapper">
        <MapContacts isMobile={isMobile} />
      </div>
      <Footer isMobile={isMobile} />
    </>
  )
}

export default Contacts
