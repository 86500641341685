import { useEffect, useRef, useState } from 'react'
import BreadCrumbs from '../../components/breadcrumbs/breadcrumbs'
import Footer from '../../components/footer/footer'
import Header from '../../components/header/header'
import Subslider from '../../components/subslider/subslider'
import { breadcrumbs, services } from '../../const'

const images = [
  '../img/services/insulation-2.jpg',
  '../img/services/insulation-3.jpg',
  '../img/services/insulation-4.jpg',
]
function Insulation({isMobile} ) {
  window.scrollTo(0,0)
  const divBlock = useRef(null);
  const [widthImage, setWidthImage] = useState(550)

  useEffect(()=>setWidthImage(divBlock.current.offsetWidth),[])
  return (
    <>
      <Header isMobile={isMobile} />
      <BreadCrumbs item={breadcrumbs.services} />
      <div className="container">
        <h1 className="services__title-1">{services[1].title}</h1>
        <div className="subservices__wrapper">
          <div className="subservices__img">
            <img src="/img/services/insulation-1.jpg" alt="construction" />
          </div>
          <div>
            <h2 className="subservices__title-2">
              ТЕПЛОВАЯ ИЗОЛЯЦИЯ ОБОРУДОВАНИЯ
            </h2>
            <p className="subservices__text">
              Работа со всеми видами тепловой изоляции. Изоляция
              теплообменников, баков, деаэраторов, резервуаров. Работа на
              высоте. Мы сами изготавливаем металлические кожухи на современных
              станках, поэтому предлагаем Заказчику конкурентную стоимость услуг
              и высокое качество исполнения.
            </p>
          </div>
        </div>
        <div className="subservices__wrapper">
          <div className="subservices__img" ref={divBlock}>
            <Subslider pictures={images} parentWidth={widthImage} />
          </div>
          <div>
            <h2 className="subservices__title-2">
              ТЕПЛОВАЯ ИЗОЛЯЦИЯ ТРУБОПРОВОДОВ
            </h2>
            <p className="subservices__text">
              Изготовление и монтаж тепловой изоляции трубопроводов.
              Качественная изоляция в условиях повышенной вибрации
              трубопроводов. Оперативное выполнение работ в сжатые сроки до 5000
              м2 / месяц.
            </p>
          </div>
        </div>
      </div>

      <Footer isMobile={isMobile} />
    </>
  )
}

export default Insulation
