import BreadCrumbs from '../../components/breadcrumbs/breadcrumbs'
import Footer from '../../components/footer/footer'
import Header from '../../components/header/header'
import { breadcrumbs, services } from '../../const'


const objectsList = [
  'ООО «Транснефть-Балтика», ремонт внутренних инженерных сетей на НПС. Регионы: Санкт-Петербург, Ленинградская область, Ярославль, Великий Новгород. 2022 г.',
  'АО «Аэропорт Пулково», ремонт внутреннего трубопровода ХВС. Регион: Санкт-Петербург 2022г.',
  'ФГАОУ ВО «СПбПУ», работы по замене индивидуального теплового пункта (ИТП) Учебного корпуса № 4, по переносу инженерных сетей отопления, водоснабжения, канализации, электроснабжения и общестроительных работ в помещениях 1,2,3,4 для обеспечения возможности переноса ИТП Учебного корпуса № 4 из помещения 4 в помещение 3. Регион: Санкт-Петербург, 2021г.',
  'СПб ГУП «Горэлектротранс», разработка рабочей документации на модернизацию горячего и холодного водоснабжения и канализации «Производственного депо». Регион: Санкт-Петербург, 2021г. ',
  'АО «ГОЗНАК», Ремонт пожарно-технического трубопровода Ду 200 в тепловом пункте , Регион: Санкт-Петербург, 2021г.',
  'ООО «ДВК-ДОРСТРОЙ», проектирование и монтаж системы отопления и вентиляции на объекте - реконструкция учебного комплекса ФГБОУ ДПО «Северо-Западный институт повышения квалификации ФНС России», г. Санкт-Петербург, ул. Костюшко, д.9, кор.2, лит. А.   Регион: Санкт-Петербург, 2020г.',
  'ООО «ДВК-ДОРСТРОЙ», Монтаж системы отопления на объекте строительства Детского дошкольного учреждения на 295 мест, расположенного по адресу: Ленинградская область, Всеволожский муниципальный район, массив Кудрово, микрорайон «Новый Оккервиль».   Регион: Ленинградская область, 2020г.',
  'АО «ГОЗНАК», Ремонт системы циркуляции ГВС здания БДМ №1 , Регион: Санкт-Петербург, 2020г.',
  'ФАУ «25 ГосНИИ химмотологии Минобороны России», модернизация центрального теплового пункта. Регион: Москва, 2019 г.',
  'ООО «РПК-Высоцк «ЛУКОЙЛ-II», ремонт трубопроводов на узлах учета «Артскважина». Регион: г.Высоцк, 2018 г.',
  'ООО «РПК-Высоцк «ЛУКОЙЛ-II», ремонт внутренних инженерных сетей зданий. Регион: г.Высоцк, 2018 г.',
  'ООО «РПК-Высоцк «ЛУКОЙЛ-II», ремонт внутренних инженерных сетей зданий. Регион: г.Высоцк, 2017 г.',
]
function Engineering({isMobile} ) {
  window.scrollTo(0,0)
  return (
    <>
      <Header isMobile={isMobile} />
      <BreadCrumbs item={breadcrumbs.services} />
      <div className="container">
        <h1 className="services__title-1">{services[6].title}</h1>
        <div className="subservices__wrapper">
          <div className="subservices__img">
            <img src="/img/services/engineering-1.jpg" alt="engineering" />
          </div>
          <div>
            <h2 className="subservices__title-2">
              ВНУТРЕННИЕ ИНЖЕНЕРНЫЕ СИСТЕМЫ
            </h2>
            <ul className="subservices__text">
              Выполнение работ по проектированию, монтажу, пуско-наладке
              внутренних инженерных систем зданий гражданского и
              производственного назначения. В штате нашей компании работают
              специалисты строительного контроля, дефектоскопист, руководители
              работ, прошедшие аттестацию в Ростехнадзоре. Благодаря
              профессиональному коллективу, мы предлагаем Заказчику полный
              комплекс услуг и берем на себя все обязательства по сдаче объекта
              «под ключ», в том числе ведение авторского надзора.
              <p className="transfer">
                Мы зарекомендовали себя на следующих объектах:
              </p>
              {objectsList.map((item) => (
                <li key={item}>
                  <strong className="strong">{item.slice(0, item.indexOf(','))}</strong>
                  {item.slice(item.indexOf(','))}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <Footer isMobile={isMobile} />
    </>
  )
}

export default Engineering
