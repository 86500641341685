import { Link } from 'react-router-dom'

function BurgerList() {
  return (
    <nav className="burger__nav">
      <ul className="burger__nav-list">
        <li className="burger__nav-item">
          <Link className="burger__nav-link" to={'/services'}>
            УСЛУГИ
          </Link>
        </li>
        <li className="burger__nav-item">
          <Link className="burger__nav-link" to={'/work'}>
            РАБОТЫ
          </Link>
        </li>
        <li className="burger__nav-item">
          <Link className="burger__nav-link" to={'/reviews'}>
            ОТЗЫВЫ
          </Link>
        </li>
        <li className="burger__nav-item">
          <Link className="burger__nav-link" to={'/licenses'}>
            ЛИЦЕНЗИИ
          </Link>
        </li>
        <li className="burger__nav-item">
          <Link className="burger__nav-link" to={'/contacts'}>
            КОНТАКТЫ
          </Link>
        </li>
      </ul>
    </nav>
  )
}

export default BurgerList
