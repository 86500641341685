import { useEffect, useRef, useState } from 'react'
import BreadCrumbs from '../../components/breadcrumbs/breadcrumbs'
import Footer from '../../components/footer/footer'
import Header from '../../components/header/header'
import Subslider from '../../components/subslider/subslider'
import { breadcrumbs, services } from '../../const'

const constructionList = [
  'проектирование;',
  'согласование;',
  'земляные работы;',
  'фундамент;',
  'инъецирование',
  'строительных конструкций;',
  'монтаж блок-модуля и дымовой трубы (сборка на объекте либо на производственной базе ООО «ЭСМ Север»);',
  'монтаж автоматики и электроснабжения;',
  'пуско-наладочные работы.',
]

const constructionImg = [
  '../img/services/construction-11.jpg',
  '../img/services/construction-12.jpg',
  '../img/services/construction-21.jpg',
  '../img/services/construction-22.jpg',
  '../img/services/construction-23.jpg',
  '../img/services/construction-31.jpg',
  '../img/services/construction-32.jpg',
  '../img/services/construction-33.jpg',
  '../img/services/construction-41.jpg',
  '../img/services/construction-42.jpg',
  '../img/services/construction-43.jpg',
]
function Constraction({isMobile} ) {
  window.scrollTo(0,0)
  const divBlock = useRef(null);
  const [widthImage, setWidthImage] = useState(550)

  useEffect(()=>setWidthImage(divBlock.current.offsetWidth),[])
  return (
    <>
      <Header isMobile={isMobile} />
      <BreadCrumbs item={breadcrumbs.services} />
      <div className="container">
        <h1 className="services__title-1">{services[0].title}</h1>
        <div className="subservices__wrapper">
          <div className="subservices__img">
            <Subslider
              pictures={constructionImg.slice(0, 2)}
              parentWidth={550}
            />
          </div>
          <div>
            <h2 className="subservices__title-2">
              СТРОИТЕЛЬСТВО КОТЕЛЬНЫХ И ТЕПЛОВЫХ ПУНКТОВ
            </h2>
            <ul className="subservices__text">
              Строительство блок-модульных котельных, центральных и
              индивидуальных тепловых пунктов, в объеме:
              {constructionList.map((item) => (
                <li key={item}>{item}</li>
              ))}
            </ul>
          </div>
        </div>
        <div className="subservices__wrapper">
          <div className="subservices__img" ref={divBlock}>
            <Subslider
              pictures={constructionImg.slice(3, 5)}
              parentWidth={widthImage}
            />
          </div>
          <div>
            <h2 className="subservices__title-2">
              СТРОИТЕЛЬСТВО ИНЖЕНЕРНЫХ СЕТЕЙ
            </h2>
            <p className="subservices__text">
              Проектирование, согласование, монтаж. Подземное и надземное
              строительство инженерных сетей в условиях городской застройки,
              наличия грунтовых вод. Работа со всеми видами материалов
              трубопроводов (труба ППУ, полимерные трубы и т.п.). Выполнение
              благоустройства. Работа в подвальных помещениях зданий с
              проведением работ по бурению технологических отверстий (кирпич,
              бетон, бутовый камень и т.п.)
            </p>
          </div>
        </div>
        <div className="subservices__wrapper">
          <div className="subservices__img">
            <div className="subservices__img">
              <Subslider
                pictures={constructionImg.slice(5, 8)}
                parentWidth={widthImage}
              />
            </div>
          </div>
          <div>
            <h2 className="subservices__title-2">КАПИТАЛЬНЫЙ РЕМОНТ КОТЛОВ</h2>
            <p className="subservices__text">
              Многолетний опыт монтажа и капитального ремонта отечественных
              котлов (ДКВр, ДЕ, ПТВМ, КВГМ и т.п.). Работа в условиях жестких
              требований к входному контролю применяемых материалов.
              Восстановление барабанов котлов под вальцовку. Высокое качество
              сварных соединений с успешным прохождением неразрушающего
              контроля. Разработка ремонтной документации и сдача работ
              инспектору Ростехнадзора. Техническое освидетельствование и
              диагностирование поверхностей нагрева котлов.
            </p>
          </div>
        </div>
        <div className="subservices__wrapper">
          <div className="subservices__img">
            <Subslider pictures={constructionImg.slice(8)} parentWidth={widthImage} />
          </div>
          <div>
            <h2 className="subservices__title-2">ОБМУРОВКА, ФУТЕРОВКА</h2>
            <p className="subservices__text">
              Выполнение работ по монтажу обмуровки, футеровки отечественных и
              современных котлов с использованием всех видов огнеупорных
              материалов. Выполнение облегченной и тяжелой обмуровок. Высокий
              уровень профессионализма в сжатые сроки и в стеснённых условиях.
            </p>
          </div>
        </div>
      </div>

      <Footer isMobile={isMobile} />
    </>
  )
}

export default Constraction
