import { Link } from 'react-router-dom'

function HeaderNav() {

  return (
    <nav className="header__nav" >
      <ul className="header__nav-list">
        <li className="header__nav-item">
          <Link
            className="header__nav-link header__nav-link--services"
            to={'/services'}
          >
            УСЛУГИ
          </Link>
        </li>
        <li className="header__nav-item">
          <Link
            className="header__nav-link header__nav-link--work"
            to={'/work'}

          >
            ВЫПОЛНЕННЫЕ РАБОТЫ
          </Link>
        </li>
        <li className="header__nav-item">
          <Link
            className="header__nav-link header__nav-link--reviews"
            to={'/reviews'}
          >
            ОТЗЫВЫ
          </Link>
        </li>
        <li className="header__nav-item">
          <Link
            className="header__nav-link header__nav-link--licenses"
            to={'/licenses'}
 
          >
            ЛИЦЕНЗИИ
          </Link>
        </li>
        <li className="header__nav-item">
          <Link
            className="header__nav-link header__nav-link--contacts"
            to={'/contacts'}

          >
            КОНТАКТЫ
          </Link>
        </li>
      </ul>
    </nav>
  )
}

export default HeaderNav
